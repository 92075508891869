import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector,useDispatch} from "react-redux";
import {
    Container,
    Grid,
    ListItemAvatar,
    Avatar,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    ListItem,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import "./Home.scss";
import Cognisphere from "../../assets/images/Cognisphere.png";
import HomeIcon from "../../assets/images/HomeImg.png";
import Calculator from '../../assets/images/Calculator.png';
import UserManagement from '../../assets/images/UserManagement.png';
import MasterData from '../../assets/images/MasterData.png';
import MasterData3WJ from '../../assets/images/MasterData.png';
import ArenaIcon from "../../assets/images/ArenaIcon.png";
import CalculatorIcon from "../../assets/images/CalculatorIcon.png";
import ConfiguratorIcon from "../../assets/images/ConfiguratorIcon.png";
import ForwardSharpIcon from '@material-ui/icons/ForwardSharp';
import {
    priceInputValuesAction,
    specsInputValuesAction,
} from '../../Redux/Actions/actions';
import certi from '../../assets/images/certi.jpg';
import { getLandingMenuList } from "../../Redux/API/api_Login";
import { storeLandingMenuItemsAction } from "../../Redux/Actions/actions";
import { baseApiURL, appId } from "../../Utilities/utility";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `300px`,
        zIndex: '10001',
        justifyContent: 'center'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        height: 320
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));


const landingPageMenuItems = [
    {
        menuId: 1,
        menuImage: Calculator,
        menuName: "ACB LP calculator",
        //  menuLink: "/mlfbinputandlist",
        menuLink: "/mlfbinput",
    },
    {
        menuId: 4,
        menuImage: UserManagement,
        menuName: "User management",
        menuLink: "/usermanagement",
    },
    {
        menuId: 3,
        menuImage: MasterData,
        menuName: "Master data update",
        menuLink: "/masterdata",
    },
    {
        menuId: 3,
        menuImage: MasterData3WJ,
        menuName: "Master Data 3WJ",
        menuLink: "/MasterData3WJ",
    },
    {
        menuId: 8,
        menuImage: certi,
        menuName: "RTC",
        menuLink: "/RTC",
    },
];

const landingPageMenuItemsExternal = [
    {
        menuId: 5,
        menuImage: ConfiguratorIcon,
        menuName: "3WA Graphical Configurator",
        menuLink: "https://mall.industry.siemens.com/mall/en/WW/Catalog/StartConfigurator?configId=39&nodeId=10313567&kmat=3WA",
        menutarget: "_blank",
    },
    {
        menuId: 6,
        menuImage: ArenaIcon,
        menuName: "ACB - EP Arena",
        menuLink: "https://siemens.sharepoint.com/teams/SIEPArena_P0014162/SitePages/SENTRON%20Air%20Circuit%20Breakers.aspx",
        menutarget: "_blank",
    },
    // {
    //     menuId: 7,
    //     menuImage: CalculatorIcon,
    //     menuName: "3WL / 3WT LP calculator",
    //     menuLink: "https://besys.siemens.co.in/ABD12_new/ZLP12xd/MRP12xx01Z9X.aspx",
    //     menutarget: "_blank",
    // },
];

const landingPageMenuItemsForSalesUser = [
    {
        menuId: 1,
        menuImage: Calculator,
        menuName: "3WA LP calculator",
        menuLink: "/mlfbinput",
    },
    {
        menuId: 2,
        menuImage: certi,
        menuName: "RTC",
        menuLink: "/RTC",
    },
    //{
    //    menuId: 2,
    //    menuImage: HomeIcon,
    //    menuName: "MLFB List",
    //    menuLink: "/mlfblist",
    //},
    ////{
    ////    menuId: 5,
    ////    menuImage: ConfiguratorIcon,
    ////    menuName: "3WA Graphical Configurator",
    ////    menuLink: "https://mall.industry.siemens.com/mall/en/WW/Catalog/StartConfigurator?configId=39&nodeId=10313567&kmat=3WA",
    ////    menutarget: "_blank",
    ////},
    ////{
    ////    menuId: 6,
    ////    menuImage: ArenaIcon,
    ////    menuName: "3WA - EP Arena",
    ////    menuLink: "https://siemens.sharepoint.com/teams/SIEPArena_P0014162/SitePages/SENTRON%20Air%20Circuit%20Breakers.aspx",
    ////    menutarget: "_blank",
    ////},
    ////{
    //    menuId: 7,
    //    menuImage: CalculatorIcon,
    //    menuName: "3WL / 3WT LP calculator",
    //    menuLink: "https://besys.siemens.co.in/ABD12_new/ZLP12xd/MRP12xx01Z9X.aspx",
    //    menutarget: "_blank",
    //},
    //{
    //    menuId: 3,
    //    menuImage: HomeIcon,
    //    menuName: "Master Data",
    //    menuLink: "/masterdata",
    //},
    //{
    //    menuId: 4,
    //    menuImage: HomeIcon,
    //    menuName: "User management",
    //    menuLink: "/usermanagement",
    //},

];

export default function Home() {

    const classes = useStyles();
    //const dispatch = useDispatch();
    // Instead of fetching the whole state (i.e., the whole store data) we are fetching only the necessary data
    //const pottsState = useSelector(state => state);

    
    const dispatch = useDispatch();
    const userDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveAzureTokenReducer.token);
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const appID = useSelector((state) => state.AppIDReducer.AppID);
    const landingMenuItems =
      useSelector((state) => state.storeLandingMenuItemsReducer.menuItems) || [];

    const [routes, setRoutes] = useState([]);
    const [extRoutes, setExtRoutes] = useState([]);

    function getMenus() {
        getLandingMenuList(
      siteToken,
      bearerToken,
      userDetails.userEmail,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,appID
    )
      .then((response) => {
        if (response) {
          console.log(response,'response');
          dispatch(storeLandingMenuItemsAction(response));
        } else {
          dispatch(storeLandingMenuItemsAction([]));
        }
      })
      .catch((error) => {
        console.log("Error: " + error, "error");
      });
        if (userDetails.roleId == 1) {
           // let filtered = landingPageMenuItems;
            let filteredExt = landingPageMenuItemsExternal;
            console.log(userDetails.roleId)
            //setRoutes(filtered);
            setExtRoutes(filteredExt);
        }
        else {
            let filteredExt = landingPageMenuItemsExternal;
            //setRoutes(landingPageMenuItemsForSalesUser);
            setExtRoutes(filteredExt);
        }
    }

     function getLandingMenuList(
        siteToken,
        bearerToken,
        email,
        gid,
        roleID,
        roleName,
        uid,appID
      ) {
        const options = {
          method: "get",
          headers: {
            Authorization: "Bearer " + bearerToken,
            UserEmail: email,
            Gid: gid,
            Roleid: roleID,
            RoleName: roleName,
            userId: uid,
            AzureToken: siteToken,
          },
        };
        return fetch(baseApiURL + "Master/GetLandingMenu?roleID=" + roleID + "&appID="+ appID, options)
          .then((response) => response.json())
          .then((data) => {
            return data.result;
          })
          .catch((error) => {
            console.log("Error : ", error);
          });
      }

    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
        window.history.pushState({ path: newurl }, "", newurl);

        const icons = {
            "<InputIcon/>": Calculator,
            "<PersonAddIcon/>":UserManagement,
            "<PersonIcon/>":MasterData,
            "<MasterData3WJ/>":MasterData3WJ,
            "<CardMembershipIcon/>":certi,
            "<InsertLink/>":ConfiguratorIcon,
            "<ViewListIcon/>":ArenaIcon
                    };

    useEffect(() => {
       
        if (
          userDetails?.userId > 0 &&
          siteToken !== "" &&
          landingMenuItems?.length <= 0
        ) {
            console.log(landingMenuItems,'landingMenuItems');
            getMenus();
          }
      }, [userDetails]);
    return (
        <>
            <img src={Cognisphere} alt="Cognisphere" className="homepage-cognisphere" />
            <div className="bg-landing-page">
                <Container maxWidth="lg">
                    <Grid container className="homepage-container">
                        <Grid item xs={12} sm={12} md={12} lg={4} className="z-9">
                            <div className="logged-in-user">
                                <h1 className="logged-in-user-heading">
                                    <span className="logged-in-greetings">Hello,</span>
                                    <span className="logged-in-user-name">
                                        {userDetails.userFirstName}{" "}
                                    </span>
                                </h1>
                                <p className="logged-in-project-desc">
                                    Welcome to Air Circuit Breaker LP Calculator Tool
                                </p>
                            </div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            className="landing-page-menu"
                            style={{ display: "flex" }}
                        >

                            <Grid item xs={12} sm={6} md={10}>
                                {landingMenuItems.map((item) => (
                                    <Grid item xs={12} key={item.index}>
                                        <a href={item.menuLink} target={item.menutarget}>
                                            <div className="menu-item-container menu-item-container-bg">
                                                <div className="menu-item-image-block menu-item-image-block-border">
                                                    <img
                                                        src={icons[item.icon]}
                                                        alt={item.menuName}
                                                        className="menu-item-image"
                                                    />
                                                </div>
                                                <div className="menu-item-text-block">
                                                    <span>{item.menuName}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            className="landing-page-menu"
                        // style={{ display: "flex", justifyContent: 'right' }}
                        >

                            <Grid item xs={12} sm={6} md={10}>
                                {landingPageMenuItemsExternal.map((item) => (
                                    <Grid item xs={12} key={item.menuId}>
                                        <a href={item.menuLink} key={item.menuId} target={item.menutarget}>
                                            <div className="menu-item-container menu-item-container-external-bg">
                                                <div className="menu-item-image-block menu-item-image-block-external-border">
                                                    <img
                                                        src={item.menuImage}
                                                        alt={item.menuName}
                                                        className="menu-item-image"
                                                    />
                                                </div>
                                                <div className="menu-item-text-block">
                                                    <span>{item.menuName}</span>
                                                </div>
                                                <div className="extLinkIconCont">
                                                    <div className='extLinkIcon'>
                                                        <ForwardSharpIcon className='extArrowIcon' />
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}
