import { Switch, Route,Redirect } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Error from "../Pages/Components/Error/Error404";
import Loading from "../Pages/Components/Loading/Loading";

export const LoginRoute = (
  <Switch>
    <Route exact path="/" render={() => <Login/>} />
    <Route render={() => <Error />} />
    <Route exact path="/signin-oidc*"render={()=><Loading/>}/>
    
  </Switch>
);
